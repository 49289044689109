<template>
  <div>
    <my-header title="问卷调查"></my-header>
    <van-tabs :active="active" titleActiveColor="#FF5809" @click="changeList">
      <van-tab title="全部" name="1" />
      <van-tab title="待填写" name="2" />
      <van-tab title="已提交" name="3" />
      <van-tab title="已过期" name="4" />
    </van-tabs>
    <div class="body">
      <div class="body_item" v-for="(item, index) in showList" :key="index">
        <p class="body_item_title">
          <span style="margin-left: -2rem">{{ index + 1 }}.</span
          >{{ item.title }}
        </p>
        <p class="body_item_sub">发布机构：{{ item.createBy }}</p>
        <p class="body_item_time">
          有效期：{{ item.endTime.split('-').join('/') }}
        </p>
        <van-button
          v-if="item.questionnaireStatus == 0"
          @click="fill(false, item)"
          type="primary"
          color="linear-gradient(90deg, #FB7A08, #FE6113)"
          class="body_item_button"
        >
          填写问卷
        </van-button>
        <van-button
          v-if="item.questionnaireStatus == 1"
          type="primary"
          color="#ffffff"
          class="body_item_button"
          @click="fill(true, item)"
          :style="{ border: '1px solid #EA5514', color: '#EA5514' }"
        >
          已提交
        </van-button>
        <van-button
          v-if="item.questionnaireStatus == 2"
          type="primary"
          color="#B5B5B6"
          class="body_item_button"
        >
          已过期
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getQuestionnaireList,
  getQuestionnaireInfo,
} from '@/api/questionnaire.js'
export default {
  data() {
    return {
      active: '1',
      list: [],
      userId: '', //用户id
    }
  },
  created() {
    this.userId = this.$store.getters.user.id
    getQuestionnaireList(this.userId).then((res) => {
      this.list = res.result
    })
  },
  computed: {
    showList() {
      if (this.active == 2) {
        return this.list.filter((item) => {
          return item.questionnaireStatus == 0
        })
      } else if (this.active == 3) {
        return this.list.filter((item) => {
          return item.questionnaireStatus == 1
        })
      } else if (this.active == 4) {
        return this.list.filter((item) => {
          return item.questionnaireStatus == 2
        })
      } else {
        return this.list
      }
    },
  },
  methods: {
    changeList(name) {
      this.active = name
    },
    fill(status, item) {
      this.$router.push({
        path: '/questionnaire/fill',
        query: {
          review: false,
          id: item.id,
          title: item.title,
          createBy: item.createBy,
        },
      })
    },
  },
}
</script>
<style lang="less" scoped>
.body {
  padding: 0 64px;
  margin-top: 64px;
  &_item {
    width: 100%;
    min-height: 379px;
    background: #ffffff;
    box-shadow: 2px 2px 9px 1px rgba(3, 3, 3, 0.05),
      -2px -2px 9px 1px rgba(3, 3, 3, 0.05);
    border-radius: 32px;
    padding: 48px 86px;
    font-family: Source Han Sans CN;
    position: relative;
    margin-bottom: 72px;
    &_title {
      width: 801px;
      font-size: 48px;
      font-weight: 400;
      color: #000000;
    }
    &_sub {
      font-size: 40px;
      font-weight: 400;
      color: #666666;
      margin-top: 50px;
    }
    &_time {
      font-size: 40px;
      font-weight: 400;
      color: #666666;
      margin-top: 8px;
    }
    &_button {
      width: 254px;
      height: 88px;
      border-radius: 8px;
      position: absolute;
      top: 251px;
      right: 48px;
    }
  }
}
::v-deep .van-button--normal {
  font-size: 40px !important;
  padding: 0;
}
</style>
